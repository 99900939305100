import React, { Component } from 'react';


class PopupWindow extends Component {

  componentDidMount() {
    //not work
    //this.scLauncher = document.querySelector('#sc-launcher');
    //this.scLauncher.addEventListener('click', this.interceptLauncherClick);
  }

  componentWillUnmount() {
    //not work
    //this.scLauncher.removeEventListener('click', this.interceptLauncherClick);
    //document.removeEventListener('click', this.interceptLauncherClick)
  }

  componentWillReceiveProps(nextProps) {
    //console.log(`is open: ${nextProps.isOpen}`)

    if (nextProps.isOpen) {
      document.addEventListener('click', this.interceptLauncherClick)
    } else {
      document.removeEventListener('click', this.interceptLauncherClick)
    }
  }

  interceptLauncherClick = (e) => {
    const { isOpen } = this.props;
    //clickedOutside && this.props.onClickedOutside(e);

    if (isOpen) {
      const clickedOutside = isOpen && !this.emojiPopup.contains(e.target) 
      
      if (clickedOutside) {
        this.props.onClickedOutside(e);
      }
    }
  }

  render() {
    const { isOpen, children } = this.props;
    return (
      <div className="sc-popup-window" ref={e => this.emojiPopup = e}>
        <div className={`sc-popup-window--cointainer ${isOpen ? '' : 'closed'}`}>
          <input
            onChange={this.props.onInputChange}
            className="sc-popup-window--search"
            placeholder="Search emoji..."
          />
          {children}
        </div>
      </div>
    );
  }
}

export default PopupWindow;
