
import React, { Component, useState, useEffect } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar'
import {hBox} from "gx-web-ui/utils/flex";
import {inject,observer} from 'mobx-react'
import {compose} from 'recompose';
import LandingPageMePanel from '../../components/setting/LandingPageMePanel'

const UserAvatarButton = props=>{
    const {onClick, avatar, uiStore, appStore, isLandingPage=false,...other} = props
    
    const {isMeSettingOpen, setIsMeSettingOpen} = uiStore

    const {logout} = appStore
    
    const logoutFunction = () =>{
        setIsMeSettingOpen(false)
        logout()
    }

    return <ButtonBase disableRipple >
                {
                    isMeSettingOpen ? <div style={{...hBox('center','center'), height:48,width:48, border :  '3px solid #fa6400', borderRadius : 50}}>
                        <div style={{...hBox('center','center'), height:44,width:44, border : '3px solid #ffffff', borderRadius : 50}}>
                            <Avatar src={avatar} onClick={onClick} style={{height:40,width:40}}/>
                        </div>
                        {
                            isLandingPage && <LandingPageMePanel onClick={logoutFunction}/>
                        }
                    </div>
                    :
                    <div style={{...hBox('center','center'), height:48,width:48}}>
                        <Avatar src={avatar} onClick={onClick} style={{height:40,width:40}}/>
                    </div>
                }
                
            </ButtonBase>           
}

export default  compose(inject('uiStore','appStore'),observer )(UserAvatarButton)