import React, { Component } from 'react';
import TextMessage from './TextMessage';
import EmojiMessage from './EmojiMessage';
import FileMessage from './FileMessage';
import ImageMessage from './ImageMessage';
import VideoMessage from './VideoMessage'
import PropertyMessage, {ProjectMessage} from './PropertyMessage';

class Message extends Component {

    _renderMessageOfType(type) {
        switch (type) {
            case 'text':
                return <TextMessage {...this.props.message} />;
            case 'emoji':
                return <EmojiMessage {...this.props.message} />;
            case 'file':
                return <FileMessage {...this.props.message} />;
            case 'image':
                return <ImageMessage {...this.props.message} />;
            case 'video':
                return <VideoMessage {...this.props.message} />;
            case 'property':
                return <PropertyMessage {...this.props.message} />;
            case 'project':
                return <ProjectMessage {...this.props.message} />;
            default:
                console.error(`Attempting to load message with unsupported file type '${type}'`);
        }
    }

  render () {
    let contentClassList = [
      'sc-message--content',
      (this.props.message.author === 'me' ? 'sent' : 'received')
    ];
    return (
      <div className="sc-message">
        <div className={contentClassList.join(' ')}>
          <div className="sc-message--avatar" style={{
            backgroundImage: `url(${this.props.imageUrl})`
          }}></div>
          {this._renderMessageOfType(this.props.message.type)}
        </div>
      </div>);
  }
}

export default Message;
