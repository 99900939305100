import React from 'react';
import {Card, Avatar, Row, Typography, message} from 'antd';

import {
    propertySlugGeneratorFromRaw
} from '../../../../../utils/slug';

const styles = {
    link: {
        color: 'rgba(0, 0, 0, 0.5)',
    },
    card: {
        cursor: 'pointer',
    }
}

const PropertyMessage = (props) => {
    const { thumbnail = "", address = "", type = "", price = "", bedrooms= 0, bathrooms = 0, carspaces = 0, getProperty = null } = props.data;
    const addressSplit = address.split(", ");
    const [link, setLink] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const _openLink = (slug) => {
        window.open(`/${slug}`, "_blank");
    }

    const _onClick = () => {
        if (link) {
            _openLink(link);
        } else if (typeof getProperty === 'function') {
            setLoading(true);
            getProperty(props.data.id)
            .then(res => {
                setLoading(false);
                console.log(res);
                const slug = propertySlugGeneratorFromRaw(res);
                setLink(slug);
                _openLink(slug);
            })
            .catch((err) => {
                setLoading(false);
                message.error('Failed to find the project');
            });
        }
    }

    return (
        <Card
            style={{ width: 400, textAlign: "left" }}
            bodyStyle={{ padding: "10px"}}
        >
            <Card.Meta
                avatar={<Avatar size={100} shape="square" src={thumbnail} />}
                onClick={_onClick}
                style={styles.card}
                title={
                    <div>
                        <div className="card-type">{addressSplit[0]}</div>
                        <div className="card-address">
                            <Typography.Link
                                style={styles.link}
                                onClick={_onClick}>{addressSplit[1]}, {addressSplit[2]}
                            </Typography.Link>
                            {
                                loading && <Typography.Text italic> (Redirecting...)</Typography.Text>
                            }
                        </div>
                        <div className="card-type">{type}</div>
                        <div className="card-price">{price}</div>
                    </div>}
                description={
                    <Row justify="start" className="attributes-info">
                        <div className="">
                            {bedrooms}
                        </div>
                        <div>
                            <img className="property-card-icon" src={require("../../assets/bedroom_icon.png")}/>
                        </div>
                        <div className="">{bathrooms}</div>
                        <div>
                            <img className="property-card-icon" src={require("../../assets/bathroom_icon.png")}/>
                        </div>
                        <div className="">{carspaces}</div>
                        <div>
                            <img className="property-card-icon" src={require("../../assets/car_icon.png")}/>
                        </div>
                    </Row>
                }
            />
        </Card>
    );
};

export const ProjectMessage = (props) => {
    const { name, thumbnail = "", address = "", type = "", price = "", bedrooms= 0, bathrooms = 0, carspaces = 0, getProject = null } = props.data;

    const [link, setLink] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const _openLink = (slug) => {
        window.open(`/${slug}`, "_blank");
    }

    const _onClick = () => {
        if (link) {
            _openLink(link);
        } else if (typeof getProject === 'function') {
            setLoading(true);
            getProject(props.data.id)
            .then(res => {
                setLoading(false);
                console.log(res);
                const slug = propertySlugGeneratorFromRaw(res);
                setLink(slug);
                _openLink(slug);
            })
            .catch((err) => {
                setLoading(false);
                message.error('Failed to find the project');
            });
        }
    }

    return (
        <Card
            style={{ width: 400, textAlign: "left" }}
            bodyStyle={{ padding: "10px"}}
        >
            <Card.Meta
                avatar={<Avatar size={100} shape="square" src={thumbnail} />}
                onClick={_onClick}
                style={styles.card}
                title={
                    <div>
                        <div className="card-type">{name}</div>
                        <div className="card-address">
                            <Typography.Link
                                style={styles.link}
                                >{address.substr(address.indexOf(',') + 1)}
                            </Typography.Link>
                            {
                                loading && <Typography.Text italic> (Redirecting...)</Typography.Text>
                            }
                        </div>
                        <div className="card-type">{type}</div>
                        <div className="card-price">{price}</div>
                    </div>}
            />
        </Card>
    );
};

export default PropertyMessage;
