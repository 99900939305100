import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Upload, Row, Col} from 'antd';
import SendIcon from './icons/SendIcon';
import FileIcon from './icons/FileIcon';
import EmojiIcon from './icons/EmojiIcon';
import PopupWindow from './popups/PopupWindow';
import EmojiPicker from './emoji-picker/EmojiPicker';
import { FolderOpenOutlined } from "@ant-design/icons/lib/icons";


class UserInput extends Component {

  constructor() {
    super();
    this.state = {
      inputActive: false,
      inputHasText: false,
      emojiPickerIsOpen: false,
      emojiFilter: ''
    };
  }

  componentDidMount() {
    this.emojiPickerButton = document.querySelector('#sc-emoji-picker-button');
  }

  handleKeyDown(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      return this._submitText(event);
    }
  }

  handleKeyUp(event) {
    const inputHasText = event.target.innerHTML.length !== 0 &&
      event.target.innerText !== '\n';
    this.setState({ inputHasText });
  }

  _showFilePicker() {
    this._fileUploadButton.click();
  }

  toggleEmojiPicker = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.state.emojiPickerIsOpen) {
      this.setState({ emojiPickerIsOpen: true });
    }
  }

  closeEmojiPicker = (e) => {
    if (this.emojiPickerButton.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({ emojiPickerIsOpen: false });
  }

  _submitText(event) {
    event.preventDefault();
    const text = this.userInput.textContent;
    if (text && text.length > 0) {
      this.props.onSubmit({
        author: 'me',
        type: 'text',
        data: { text }
      });
      this.userInput.innerHTML = '';
    }
  }

  _onFilesSelected(event) {
      this.props.onFilesSelected(event);
  }

  _handleEmojiPicked = (emoji) => {
    this.setState({ emojiPickerIsOpen: false });
    if(this.state.inputHasText) {
      this.userInput.innerHTML += emoji;
    } else {
      this.props.onSubmit({
        author: 'me',
        type: 'emoji',
        data: { emoji }
      });
    }
  }

  handleEmojiFilterChange = (event) => {
    const emojiFilter = event.target.value;
    this.setState({ emojiFilter });
  }

  _renderEmojiPopup = () => (
    <PopupWindow
      isOpen={this.state.emojiPickerIsOpen}
      onClickedOutside={this.closeEmojiPicker}
      onInputChange={this.handleEmojiFilterChange}
    >
      <EmojiPicker
        onEmojiPicked={this._handleEmojiPicked}
        filter={this.state.emojiFilter}
      />
    </PopupWindow>
  )

  _renderSendOrFileIcon() {
    if (this.state.inputHasText) {
      return (
        <div className="sc-user-input--button">
          <SendIcon onClick={this._submitText.bind(this)} />
        </div>
      );
    }

    return (
      <div className="sc-user-input--button">
          <Upload
              showUploadList={false}
              action={this.props.action}
              name="file"
              transformFile={this.props.transformFile}
              data={this.props.fileData}
              onChange={this._onFilesSelected.bind(this)}
          >
              <FileIcon  />
          </Upload>
      </div>
    );
  }

  render() {
    const { emojiPickerIsOpen, inputActive } = this.state;
    return (
        <Row>

            <Col span={24}>
                <div className="sc-user-input-tool">
                    <Row align="middle" style={{height: "50px", width: "100%", padding: "0 20px"}}>
                        <Col span={2}>
                            {this.props.showEmoji && <EmojiIcon
                            onClick={!this.props.disable?this.toggleEmojiPicker:() => {}}
                            isActive={emojiPickerIsOpen}
                            tooltip={this._renderEmojiPopup()}
                        />}</Col>
                        <Col span={2} style={{textAlign: "center"}}>
                            <Upload
                                accept={this.props.accept}
                                disabled={this.props.disable}
                            showUploadList={false}
                            action={this.props.action}
                            name="file"
                            transformFile={this.props.transformFile}
                            data={this.props.fileData}
                            onChange={this._onFilesSelected.bind(this)}
                        >
                            <FolderOpenOutlined className="sc-user-input--file-icon" />
                        </Upload></Col>
                        <Col span={20} style={{textAlign: "right"}}>
                            <SendIcon onClick={this._submitText.bind(this)} /></Col>
                    </Row>

                </div>
            </Col>
            <Col span={24}>
                <form className={`sc-user-input ${(inputActive ? 'active' : '')}`}>

                    <div
                        role="button"
                        tabIndex="0"
                        onFocus={() => { this.setState({ inputActive: true }); }}
                        onBlur={() => { this.setState({ inputActive: false }); }}
                        ref={(e) => { this.userInput = e; }}
                        onKeyDown={this.handleKeyDown.bind(this)}
                        onKeyUp={this.handleKeyUp.bind(this)}
                        contentEditable={this.props.disable?"false":"true"}
                        placeholder="Write a reply..."
                        className="sc-user-input--text"
                    >
                    </div>
                </form>
            </Col>

        </Row>
    );
  }
}

UserInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool
};

export default UserInput;
