import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox} from "gx-web-ui/utils/flex";
import { Slide,Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { CalculatorFilled } from '@ant-design/icons';
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'
import { ReactComponent as Logout } from '@src/assets/svgIcons/Logout.svg'

import MeSettingComponent from "./MeSettingComponent"
import useWindowDimensions from '../../utils/windowDimension'

const useStyles = makeStyles((theme) => ({
    root: {
        ...hBox('center','center'),
        height : 40,
        width:100,
        position: 'relative',
        height: '100%',
        background : '#f5f5f5',
        borderRadius: 4
    }
}));

const LandingPageMePanel = (props) => {


    const { onClick,uiStore,parentDivWidth=null } = props

    const {isMeSettingOpen,setIsMeSettingOpen} = uiStore

    // const {logout} = appStore

    const classes = useStyles();

    useEffect(() => {
    }, [])

    return(
        <div  style={{
            position :'fixed',
            top : 58,
            // paddingRight : parentDivWidth ? parentDivWidth < 1440 ? 0 : 80 :  0,
            // right :  parentDivWidth ? parentDivWidth < 1440 ? 0 : ((width - parentDivWidth) / 2 + 45)  : 0,
            zIndex: 100,
            height: 40
        }}>
            <Paper onClick={onClick} className={classes.root}>
                <Logout style={{width:24, height:24,color : '#80808066'}}/>
                <Typography style={{'color' : 'black',marginLeft : 12}} variant="body1" >Logout</Typography>
            </Paper >
        </div>
    )
}

export default compose(inject('uiStore'),observer)(LandingPageMePanel)