import React,{useEffect,useState} from 'react'
import { Layout, Badge, Dropdown, Menu } from "antd"
import { makeStyles } from '@material-ui/core/styles';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

const BusinessMenu = (props) => {
    const {textColor} = props

    const menu = (
        <Menu>
            <Menu.Item>
            <a style={{fontSize: 14}} target="_blank" rel="noopener noreferrer" onClick={props.onClientLogin}>
                Client Login
            </a>
            </Menu.Item>
            <Menu.Item>
            <a style={{fontSize: 14}} target="_blank" rel="noopener noreferrer" href="https://developer.teamlink.com.au">
                Developer Login
            </a>
            </Menu.Item>
            <Menu.Item>
            <a style={{fontSize: 14}} target="_blank" rel="noopener noreferrer" href="https://agency.teamlink.com.au">
                Agency Login
            </a>
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu} overlayStyle={{paddingTop: -10}}>
            <a style={{fontSize: 16, fontWeight: '500', marginRight: 48, paddingTop: 0, color: textColor ? textColor : '#a7a7a7'}}>
                <UserOutlined />
                <span style={{ margin: '0 8px' }}>Login</span>
                <DownOutlined />
            </a>
        </Dropdown>
    )
}

export default BusinessMenu

