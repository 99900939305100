import React, { useState, useEffect, useRef } from "react";
import { Card, Badge, Spin } from "antd";
import hook from "../../gx-web-ui/hook";
import dayjs from "dayjs";
import MessagePanel from "commons/components/message/MessagePanel";
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import "./index.less";

const Messages =  ({  visible, notice, message, groups, appStore, onClose, uiStore }) => {
    //const [visible, setVisible] = useState(true);
    const [messages, setMessages] = useState({});
    const [activeKey, setActiveKey] = useState('0');
    const [refresh, setRefresh] = useState(false);
    const [groupId, setGroupId] = useState(0);
    const [search, setSearch] = useState('');
    const [contacts, setContacts] = useState(null);
    const {id} = appStore.loggedInUser;
    const {messageReceiverId, setMessageReceiverId, isMessagePanelVisible, sentPropertyListing} = uiStore
    const timerIdRef = useRef(null)
    const sendingEnquiry = useRef(false)
        
    const ossData = hook.useAsyncCall(() => appStore.client.post('oss-policy-signature', {
        entity: 'user',
        id: id
    }, null, {endpoint: appStore.settings.fndEndpoint}));

    //console.log('>>>>ids', readGroupIds.current)

    useEffect(() => {
        //console.log('message panel visible', isMessagePanelVisible)
        //commented after adding sending enquiry when showing message panel
        sendGreetingMessage()

        timerIdRef.current = setInterval(() => {
            //console.log('refresh', `groupid: ${groupId}, visible:${visible}`)
            if (isMessagePanelVisible || visible) {
                getGroupMessages(groupId)
            }
        }, 10000)

        return () => {
            clearInterval(timerIdRef.current)
        }
    }, [isMessagePanelVisible, groupId, visible])

    const sendGreetingMessage = async () => {
        if (isMessagePanelVisible && messageReceiverId != 0) {
            //console.log('>>>>id, say hi', messageReceiverId)
            sendingEnquiry.current = true
            setActiveKey('2')

            if (!sentPropertyListing) {
                const text = 'Hi'
                const { status, response = [] } = await appStore.client.post(["message"],
                    {msg: {text: text}, receiverId: messageReceiverId}
                );

                if (status === "success") {
                    setRefresh(pre => !pre)
                    //updateLatestMessage(id, text)
                }
            } else {
                onChange(contactsList[2].id)
            }

            setMessageReceiverId(0)
        }
    }

    const { result: contactsList = [] } = hook.useAsyncCall(() => appStore.client.get(["message-feed"], search && search.length >= 2 ? {
        search : search
    } : {}),
        [appStore.contentAreaRefresh, refresh, search],
        ({response = []}) => {
            //console.log('res>>>', response)

            if(response.length > 0) {
                
                if (groupId === 0) {
                    setTimeout(() => {
                        //console.log('>>>res', response)
                        onChange(sendingEnquiry.current ? response[2].id : response[0].id)

                        sendingEnquiry.current = false

                    }, 300)
                } else {
                    if (sendingEnquiry.current) {
                        onChange(response[2].id)
                        sendingEnquiry.current = false
                    }
                }
            }

            return response;
        }
    );

    //console.log('>>>contacts list', contactsList)

    const list = contactsList.map(item => {

        const messageNum = groupId === item.id ? 0 : (item.id < 100  ? item.numUnread : (item?.[":users"]?.[0]?.numUnread || 0)) 

        return {
            description: JSON.parse(  item.id < 100  ? (item[":latestMessage"]?.msg || "{}") : item['minMsgIndex'] ? (item[":latestMessage"]?.id > item['minMsgIndex']) ? (item[":latestMessage"]?.msg || "{}") : "{}" : (item[":latestMessage"]?.msg || "{}"))?.text,
            time: item[":latestMessage"] ? dayjs(item[":latestMessage"]?.createdAt).format("DD/MM/YYYY") : "",
            disableReply: item.id === 1 || item.id === 3,
            messageNum,
            ...item
        }
    });
    
    
    const dir = `user/${appStore.loggedInUser.id}/messages/`

    const onSendMessage = async (message, id) => {
        const index = contactsList.findIndex((e) => id === e.id)
        const receiverId = contactsList[index].peer || 2
        console.log(message, id, receiverId)
        const msg = message.data.text ? {text: message.data.text} : message.data.emoji ? {text: message.data.emoji} : {image: message.data.url}

        const { status, response = [] } = await appStore.client.post(["message"],
            {msg, groupId: id}
        );
        console.log(id, receiverId, status, response)
        if (status === "success") {
            //appStore.refreshContentArea();
            updateLatestMessage(id, message.data.text)
        }
    }

    const updateLatestMessage = (id, latest) => {
        const index = contactsList.findIndex((e) => {return id === e.id})
        
        if (index >= 0) {
            contactsList[index].latest = latest
            refreshFunction();
        }
    }

    const getGroupMessages = async (id) => {
        const { status, response = [] } = await appStore.client.get(["message"],{groupId: id});
        let latest = ''
        if (status === "success") {
            //console.log(status, `group id: ${id}`, 'response length', response.length, response)
            appStore.refreshHeartBeat()
            setRefresh(pre => !pre)
            
            setMessages(messages => {
                messages[id] = response.map(item => {
                    const typeData = {};
                    const action = item.sender === appStore.loggedInUser.id ? 'sent' : 'received'
                    if (JSON.parse(item.msg).image) {
                        //console.log('image', item)
                        typeData.type = "image";
                        typeData.data = { url: JSON.parse(item.msg).image, filename: ""};
                        latest = `You ${action} an image`
                    } else if (JSON.parse(item.msg).video) {
                        typeData.type = "video";
                        typeData.data = { url: JSON.parse(item.msg).video, filename: ""};
                        latest = `You ${action} a video`
                    } else if (JSON.parse(item.msg).property) {
                        typeData.type = "property";
                        typeData.data = JSON.parse(item.msg).property;
                        typeData.data.getProperty = appStore.clientApi.getProperty_;
                        latest = `You ${action} a property listing`
                    } else if (JSON.parse(item.msg).project) {
                        typeData.type = "project";
                        typeData.data = JSON.parse(item.msg).project;
                        typeData.data.getProject = appStore.clientApi.getProject_;
                        latest = `You ${action} a project listing`
                    }else {
                        typeData.type = "text";
                        typeData.data = { text: JSON.parse(item.msg).text };
                        latest = typeData.data.text
                    }
                    return {
                        author: id < 100 ? 'them' : (item.user || item.sender || item.customer) && (item.user === appStore.loggedInUser.id || item.sender === appStore.loggedInUser.id || item.customer === appStore.loggedInUser.id) && id !== 1 ? "me" : "them",
                        ...typeData
                    }
                });
                return {...messages};
            });

            //console.log(messages)
            const currentMessages = response
            if (currentMessages && currentMessages.length > 0) {
                updateLatestMessage(id, latest)
            }
        }

    }

    const onChange = async (id) => {
        //console.log('on change group id>>>', id)
        setGroupId(id)
        await getGroupMessages(id)
    }

    const refreshFunction = () =>{
        setRefresh(pre => !pre)
    }

    const onSearch = (value) => {
        setSearch(value);
    };

    return (visible &&
        <Spin spinning={sendingEnquiry.current}>
            <div className="message">
                <MessagePanel
                    contacts={list}
                    ossData={ossData}
                    fileDir={dir}
                    onSearch={onSearch}
                    onSendMessage={onSendMessage}
                    onChange={onChange}
                    appStore={appStore}
                    messages={messages}
                    visible={visible}
                    onClose={() => onClose()}
                    activeKey={activeKey}
                    onChangeActiveKey={setActiveKey}
                    refreshFunction={refreshFunction}
                />
            </div>
        </Spin>
    );
};

export default compose(inject('appStore','uiStore'),observer)(Messages)