import React, {useState} from 'react';
import {Image} from 'antd';

const ImageMessage = (props) => {
    return (
        <>
        <Image className="sc-message--image" src={props.data.url} alt={props.data.fileName} />            
        </>
    );
};

export default ImageMessage;
