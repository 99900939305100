import React, {useEffect, useState} from "react";

import {List, Avatar, Empty, Tabs, Row, Badge, Col, Input,Spin} from "antd";
import {AudioMutedOutlined, SearchOutlined, PushpinOutlined} from "@ant-design/icons";
import {Launcher} from './react-chat-window'
import "./MessagePanel.less";
import { MenuProvider } from 'react-contexify';
import RightClickMenu from './RightClickMenu' 
const defaultAvatar = 'https://teamlink-ci.oss-ap-southeast-2.aliyuncs.com/images/test/black-profile-icon-illustration-user-profile-computer-icons-login-user-avatars-png-clip-art-thumbnail.png'


const { TabPane } = Tabs;
const MessagePanel = ({ossData, fileDir, contacts = [], onSendMessage, onChange, activeKey,onChangeActiveKey ,messages={},onClose, visible, rowKey,onSearch=()=>{}, appStore, refreshFunction  }) => {
    const [visibleIn, setVisible] = useState(visible?visible:true);
    const [messageList, setMessageList] = useState({});
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        setMessageList({...messages})
    }, [messages])

    useEffect(() => {
        setVisible(visible);
    }, [visible])

    const searchNode = () => {
        return (
            <Input onChange={(e)=>onSearch(e.target.value)} prefix={<SearchOutlined className="site-form-item-icon" />} placeholder="Search here" style={{margin: "5px 0"}} />
        )
    }

    const onStick_ = async(id, isOnTop) =>{
        await appStore.client.put(["chat-group",id],{
            stickOnTop : isOnTop ? false : true
        })
        refreshFunction()
    }

    const onMute_ = async(id, isOnMute) =>{
        await appStore.client.put(["chat-group",id],{
            muteNotification : isOnMute ? false : true
        })
        refreshFunction()
    }
    const onClearHistory_ = async(id) =>{
        await appStore.client.put(["chat-group",id],{
            clearHistory : true
        })
        messages[id] = []
        setMessageList({...messages})
        refreshFunction()
    }
    
    const onDelete = ()=>{}

    const renderTabHeader = (item) => {
        return (
            <div>
                <MenuProvider id={`menu_id-${item.id}`}>
                    <List>
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <Badge count={item.messageNum} dot={item.dot}>
                                        <Avatar src={item.avatar || defaultAvatar} size={40} />
                                    </Badge>
                                }
                                title={<Row justify="space-between" className="message-list-title"><a>{item.name}</a><span>{item.time}</span></Row>}
                                description={
                                    <Row justify="space-between" align="middle">
                                        <Col span={16}><div className="message-list-content">{item.description}</div></Col>
                                        {Boolean(item.stickOnTop) && <Col><div className="message-list-extra">{<PushpinOutlined />}</div></Col>}
                                        {Boolean(item.muteNotification) && <Col><div className="message-list-extra">{<AudioMutedOutlined />}</div></Col>}
                                    </Row>
                                }
                            />
                        </List.Item>
                    </List>
                </MenuProvider>
            </div>
        )
    }
    const _onMessageWasSent = (message, id, key) => {
        setMessageList( messageList => {
            if (messageList[id]) {
                messageList[id] = [...messageList[id], message];
            } else {
                messageList[id] = Array.from([message]);
            }
            return {...messageList}
        });
        onSendMessage && onSendMessage(message, key || id);
    }

    const _onFilesSelected = ({file}, id, key) => {
        if (!file) {
            return false
        }

        const { status, url, type } = file;

        if (status === "done") {
            setMessageList(messageList => {
                messageList[id] = [...(messageList[id] || []), {
                    author: 'me',
                    type: type.includes("image") ? 'image' : "video",
                    data: {
                        url: ossData?.result?.host+ "/" + file.url,
                        fileName: file.name
                    }}];
                return {...messageList};
            })
            onSendMessage && onSendMessage({
                author: 'me',
                type: type.includes("image") ? 'image' : "video",
                data: {
                    url: ossData?.result?.host+ "/" + file.url,
                    fileName: file.name
                }}, key || id);
        }

    }

    // const _sendMessage = (text) => {
    //     if (text.length > 0) {
    //         setMessageList( messageList => [...messageList, {
    //             author: 'them',
    //             type: 'text',
    //             data: { text }
    //         },{
    //             author: 'them',
    //             type: 'image',
    //             data: { url: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png", fileName: "Any old name" }
    //         }])
    //     }
    // }

    const transformFile = (file) => {
        const suffix = file.name.slice(file.name.lastIndexOf("."));
        const filename = Date.now() + suffix;
        const imageDir = fileDir;
        file.url = imageDir + filename;
        return file;
    };

    const tabChange = (key) => {
        // const { id = ""} = contacts[key];
        console.log('tab change>>>')
        onChange && onChange(key)
        onChangeActiveKey && onChangeActiveKey(key)
    }

    const onInnerClose = () => {
        if (onClose) {
            onClose();
        } else {
            setVisible(visible? visible :false);
        }
    }

    return (
            visibleIn &&
                <div className="message-panel">
                    <Spin spinning={contacts.length < 1}>
                        <Tabs activeKey={activeKey} tabPosition="left" className="message-table" onChange={tabChange}>
                            <TabPane disabled tab={searchNode()} key={"other"} className="message-table-pane"  />
                            {
                                contacts.map((item, index) => (
                                    <TabPane tab={renderTabHeader(item)} key={item.id} >
                                        <Launcher
                                            isOpen={isOpen}
                                            agentProfile={{
                                                teamName: item.name,
                                                imageUrl: item.avatar || defaultAvatar
                                            }}
                                            noInput={item.name === 'Notification'}
                                            onClose={() => onInnerClose()}
                                            disable={item.disableReply}
                                            onFilesSelected={(file) => _onFilesSelected(file, item.id, item[rowKey])}
                                            onMessageWasSent={(message) => _onMessageWasSent(message, item.id, item[rowKey])}
                                            messageList={messageList[item.id] || []}
                                            showEmoji
                                            accept="video/*, image/*"
                                            action={ossData?.result?.host}
                                            fileData={(file) => {
                                                return {
                                                    key: file.url,
                                                    OSSAccessKeyId: ossData?.result?.accessId,
                                                    policy: ossData?.result?.policy,
                                                    Signature: ossData?.result?.signature,
                                                }
                                            }}
                                            transformFile={transformFile}
                                        />
                                    </TabPane>
                                ))
                            }
                        </Tabs>
                    </Spin>
                    {contacts.map(item=>
                        item.id >= 100 && <RightClickMenu
                            key={item.id}
                            id={`menu_id-${item.id}`}
                            stickyStatus={item.stickOnTop}
                            muteStatus={item.muteNotification}
                            onStick={()=>onStick_(item.id, item.stickOnTop)} 
                            onMute={()=>onMute_(item.id, item.muteNotification)} 
                            onClearHistory={()=>onClearHistory_(item.id)} 
                            onDelete={onDelete}
                        />
                    )}
                </div>

    )
};

export default MessagePanel;
