import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'

const isProject = (rawData)=>{
    return rawData.project === undefined
}
export const propertySlugGenerator = (type,displayName,postcode,state,id)=>{
    return `${type}-${kebabCase(displayName)}-${state}-${postcode}-${id}`
}
export const propertySlugGeneratorFromRaw = (rawData)=>{
    return `${isProject(rawData)?'project':'property'}-${kebabCase(rawData.displayName)}-${get(rawData,':address.state')}-${get(rawData,':address.postcode')}-${rawData.id}`
}
export const propertySlugGeneratorFromProcessedData = (data)=>{
    return `${data.isProject?'project':'property'}-${kebabCase(data.displayName)}-${get(data,'address.state')}-${get(data,'address.postcode')}-${data.projectId}`
}