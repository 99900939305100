import React, { Component } from 'react';

import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";


class Header extends Component {

  render() {
    return (
      <div className="sc-header">
        <img className="sc-header--img" src={this.props.imageUrl} alt="" />
        <div className="sc-header--team-name"> {this.props.teamName} </div>
        <div className="sc-header--close-button" onClick={this.props.onClose}>
            <CloseOutlined />
        </div>
      </div>
    );
  }
}

export default Header;
