import React from 'react';
import { SmileOutlined } from "@ant-design/icons/lib/icons";


const EmojiIcon = ({ tooltip, onClick, isActive }) => (
  <div className="sc-user-input--picker-wrapper">
    {tooltip}
    <button id="sc-emoji-picker-button" className="sc-user-input--emoji-icon-wrapper" onClick={onClick}>
        <SmileOutlined className="sc-user-input--file-icon"/>
    </button>
  </div>
);

export default EmojiIcon;
