import React from 'react';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import Typography from '@material-ui/core/Typography'
// import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import 'react-contexify/dist/ReactContexify.min.css';

const RightClickMenu = (props) => {
    const {id,onStick,onMute,onClearHistory,onDelete, stickyStatus, muteStatus} = props

    return (
        <Menu id={id} style={{zIndex:1500}} className={'ignore-class'} key={id}>
            <Item onClick={onStick}>
                <Typography >  
                    {Boolean(stickyStatus) ? `Unstick on top` : `Stick on top`}
                </Typography>
            </Item>
            <Item onClick={onMute}>
                <Typography >  
                    {Boolean(muteStatus) ? `Unmute notification` : `Mute notification`}
                </Typography>
            </Item>
            <Item onClick={onClearHistory}>
                <Typography >  
                    Clear history
                </Typography>
            </Item>
            {/* <Separator />
            <Item onClick={onDelete}>
                <Typography style={{color:'#fa6400'}}>  
                    Delete
                </Typography>
            </Item> */}
        </Menu>
    )
}

export default RightClickMenu
