import React, { useState } from 'react';
import { Dropdown, Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import hook from "../../gx-web-ui/hook";
import Messages from './Messages';
import {inject, observer} from "mobx-react";
import {compose} from "recompose";
import useOnclickOutside from "react-cool-onclickoutside";

const MessageMenu = compose(inject('appStore','uiStore'),observer)(({appStore, visible,setVisible,style}) => {
    /*const message = hook.useAsyncCall(
        () => appStore.client.get(["message-feed"]),
        [appStore.contentAreaRefresh]
    );*/

    /*const notice = hook.useAsyncCall(
        () => appStore.client.get(["message"], {groupId: 1}),
        [appStore.contentAreaRefresh]
    );*/

    /*const group = hook.useAsyncCall(
        () => appStore.client.get(["message"], {groupId: 3}),
        [appStore.contentAreaRefresh]
    );*/

    const containerRef = useOnclickOutside(() => {
        setVisible(false)
    },{
        ignoreClass:'ignore-class',
    });

    //const { response: myMessageT = [] } = message.result || {};
    //const { response: myNotice = [] } = notice.result || {};
    //const { response: myGroup = [] } = group.result || {};

    //const myMessage = myMessageT.filter(item => item.id !== 1);

    const trigger = (
        <div style={{ alignItems: "center"}}>
            <Badge count={2} style={{ boxShadow: 'none' }} className="badge" offset={[0, 5]}>
                <BellOutlined className="trigger"/>
            </Badge>
        </div>
    );

    const onClose = () => {
        setVisible(false);
    }

    return <div ref={containerRef} style={{maxWidth: "900px",backgroundColor: "white", position:'absolute', ...style, right: 0}}>
            {appStore.loggedInUser && <Messages visible={visible} onClose={onClose}  appStore={appStore} />}
        </div>

})

const MessageMenuEnter = (props)=>{
    return props.visible && <MessageMenu {...props}/>
}

export default compose(inject('appStore','uiStore'),observer)(MessageMenu)
