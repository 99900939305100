import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox} from "gx-web-ui/utils/flex";
import { Slide,Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { CalculatorFilled } from '@ant-design/icons';

import MeSettingComponent from "./MeSettingComponent"
import useWindowDimensions from '../../utils/windowDimension'
import { motion, useCycle } from "framer-motion"; 

const sidebar = {
    open: {
        x:0,
        display:'block',
    },
    closed: {
        x:700,
        transitionEnd:{
            display:'none'
        }
    }
};

const useStyles = makeStyles((theme) => ({
    paper: {
        width:476,
        height:'100vh',
        position: 'relative',
        background : '#f5f5f5',
        // background : 'rgb(0,0,0,0.5)',
        // overflow: 'scroll',
        borderRadius: 0
    }
    // dialog :{
    //     position :'fixed',
    //     // width : '100%',
    //     // right : parentWidth >=1440 ? parentWidth-1440 : 0, 
    //     // top:64,
    //     zIndex: 2000,
    //     height: "100%",
    //     ...hBox('space-between')
    // },
    // paper: {
    //     width:476,
    //     position: 'relative',
    //     right :0, 
    //     top:64,
    //     height: '100%',
    //     background : '#f5f5f5',
    //     // background : 'rgb(0,0,0,0.5)',
    //     borderRadius: 0
    // }
}));
const MePanel = (props) => {


    const { appStore,uiStore,parentDivWidth=null } = props

    const {isMeSettingOpen} = uiStore

    const {width,height} = useWindowDimensions()

    // const [rightPostion, setRightPostion] = useState()

    const classes = useStyles();

    useEffect(() => {
    }, [])

    const [activeKey, setActiveKey] = useState("1")

    const onChange = (actKey) =>{
        setActiveKey(actKey)
    }

    return(
            <motion.div 
                initial={false}
                animate={uiStore.isMeSettingOpen ? "open" : "closed"}
                style={{
                    position :'absolute',
                    top:0,
                    right:0,
                    zIndex: 100,
                    height: "100%",
                    ...hBox('space-between')
                }}
                variants={sidebar}
                transition={{duration:0.2}}
                //ref={containerRef}
            >
                <div style={{position:"sticky",top:0}}>
                    <Paper elevation={3} className={classes.paper} >
                        <MeSettingComponent />
                    </Paper>
                </div>
            </motion.div>
    )
}

export default compose(inject('appStore','uiStore'),observer)(MePanel)